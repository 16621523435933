import React from 'react'
import { addShelf, removeShelf } from '../shelf-api-model'
import { LifecycleHandlers, Stock } from '../types'

type AnyParams = { [keys: string]: any }

/** Deprecated interface for rendering a component on the shelf */
const ComponentInjector = ({
  item,
  params,
}: {
  item: Stock
  params: AnyParams
}) => {
  const Component = item.component
  return (
    <div data-testid={item.componentId}>
      {/** @ts-ignore: params are unknown */}
      <Component {...params} />
    </div>
  )
}

/** New interface for adding a dom node placeholder and relying on the stock to render and reparent itself */

const PlaceholderInjector = ({
  item,
  placeholderId,
  params,
}: {
  item: Stock
  placeholderId: string
  params: AnyParams
}) => {
  const onLifecycleRef = React.useRef<LifecycleHandlers>(null)
  const placeholderIdRef = React.useRef<string>(placeholderId)

  const placeholderCallbackRef = React.useCallback(
    (element) => {
      if (!element) {
        return
      }

      const onRender = onLifecycleRef.current?.render
      if (onRender) {
        onRender(params)
      } else {
        const lifecycle = item.onMount(element, placeholderId, params)
        onLifecycleRef.current = lifecycle
      }
    },
    [item, params, placeholderId]
  )

  React.useEffect(() => {
    const placeholderIdCurrent = placeholderIdRef.current

    return () => {
      // unmount stock if placeholder unmounts
      const onUnmount = onLifecycleRef.current?.unmount
      if (onUnmount) {
        // unmount stock if it there was a previous stock component on the placeholder
        onUnmount(placeholderIdCurrent)
        onLifecycleRef.current = null
      }
    }
  }, [])

  return <div data-testid={item.componentId} ref={placeholderCallbackRef}></div>
}

export const Shelf = ({
  name,
  shelfId = name,
  params,
}: {
  name: string
  shelfId?: string
  params: AnyParams
}) => {
  const [stock, setStock] = React.useState<Array<Stock>>([])

  const setStockCb = React.useCallback((stock) => {
    setStock(stock)
  }, [])
  const nameRef = React.useRef<string>()
  if (nameRef.current && nameRef.current !== name) {
    removeShelf(nameRef.current)
  }
  nameRef.current = name

  React.useEffect(() => {
    addShelf(name, setStockCb)

    return () => {
      removeShelf(name)
    }
  }, [name, setStockCb])

  return (
    <div data-testid='shelf'>
      {stock.map((item) => {
        if (item.onMount) {
          return (
            <PlaceholderInjector
              key={item.componentId}
              placeholderId={`${shelfId}_${item.componentId}`}
              item={item}
              params={params}
            />
          )
        } else {
          return (
            <ComponentInjector
              key={item.componentId}
              item={item}
              params={params}
            />
          )
        }
      })}
    </div>
  )
}
