import { Subject } from 'rxjs'
// TODO: Add as secundo package. Perhaps autogenerated.

export type VisibilityEnum =
  | 'TOAST_ONLINE_ORDERING'
  | 'GRUBHUB'
  | 'ORDERING_PARTNERS'
  | 'TOAST_ONLINE_ORDERING'
  | 'POS'
  | 'KIOSK'

export type CartApiType = 'OO' | 'OPT'

export interface ModifierGroup {
  guid: string
  modifiers: Array<Modifier>
}

export interface Modifier {
  itemGuid: string
  itemGroupGuid: string
  quantity: number
  modifierGroups: Array<ModifierGroup>
}

export interface Selection extends Modifier {
  specialInstructions: string
  itemMasterId: string
}

export interface Header {
  response$: Subject<UpdateCartResponse>
}

export interface UpdateCartRequest {
  header: Header
  selection: Selection
  selectionGuid?: string
  isReorder?: boolean
}
export interface EditItemUserRequest {
  cartGuid: string
  selectionGuid: string
  itemGuid: string
  itemGroupGuid: string
  visibility: VisibilityEnum
  cartApi: CartApiType
}

export enum ResponseTypes {
  OK = 'OK',
  ERROR = 'ERROR'
}

export interface CartModificationResponse {
  // FIXME: Use generated types from BFF schema
  cart: any
  info: Array<{
    code: any
    message: string
  }>
  warnings: Array<{
    code: any
    message: string
  }>
}

export interface CartResponse extends CartModificationResponse {
  kind: ResponseTypes.OK
}

export interface CartError {
  code: any
  message: string
  items?: Array<{
    name: string
    guid: string
  }>
}

export interface CartErrorResponse extends CartError {
  kind: ResponseTypes.ERROR
}

export type UpdateCartResponse = CartResponse | CartErrorResponse

export interface Fulfillment {
  selected: boolean
  fulfillmentTime: string | null
}
