import React from 'react'
import { createPortal } from 'react-dom'
import { addStock } from '../shelf-api-model'
import { AnyParams, StockBoxConfig } from '../types'

interface MountedConfig {
  component: React.Component | React.FC
  params: AnyParams
  placeholderId: string
  container: Element
}

/**
 * Only update if the entity or params have changed. Note we require a new params object
 * which shelves already takes care of.
 *
 * Passes in a traditional function instead of a arrow function to keep
 * react-performance-testing from complaining about anonymous components.
 */
const MountedComponent = React.memo<{
  entity: MountedConfig
  params: AnyParams
}>(function MemoComponent({ entity, params }) {
  return (
    <div>
      {(() => {
        const ProjectedComponent = entity.component

        const portal = createPortal(
          // @ts-ignore
          <ProjectedComponent {...params} />,
          entity.container
        )

        return portal
      })()}
    </div>
  )
})

/**
 * Helper to render stock as portals
 */
export const StockBox = ({ stockConfig }: { stockConfig: StockBoxConfig }) => {
  const mountedRef = React.useRef<{
    [key: string]: MountedConfig
  }>({})

  const [mounted, setMounted] = React.useState(mountedRef.current)

  React.useEffect(() => {
    stockConfig.shelves.forEach(shelf => {
      shelf.components.forEach(({ component, componentId, priority }) => {
        addStock({
          shelfName: shelf.name,
          componentId,
          priority,
          onMount: (
            container: Element,
            placeholderId: string,
            params: AnyParams
          ) => {
            // we use a ref here to avoid reexecuting this block on mounted change
            mountedRef.current[placeholderId] = {
              component,
              params,
              placeholderId,
              container
            }

            setMounted({ ...mountedRef.current })

            return {
              unmount: (placeholderId: string) => {
                delete mountedRef.current[placeholderId]
                setMounted({ ...mountedRef.current })
              },
              render: (params: AnyParams) => {
                const mountedComponentConfig = mountedRef.current[placeholderId]
                if (mountedComponentConfig) {
                  mountedRef.current[placeholderId] = {
                    component: mountedComponentConfig.component,
                    params,
                    placeholderId,
                    container: mountedComponentConfig.container
                  }
                  setMounted({ ...mountedRef.current })
                }
              }
            }
          }
        })
      })
    })
  }, [stockConfig, setMounted])

  return Object.values(mounted).map((entity: MountedConfig) => (
    <MountedComponent
      key={entity.placeholderId}
      entity={entity}
      params={entity.params}
    />
  ))
}
