import { BehaviorSubject } from 'rxjs'
import { AuthClient, AuthContextType } from '../types'

export const authContextDefaultValues: AuthContextType = {
  error: null,
  loading: true,
  user: null,
  authenticated: false,
  authClient: null, // Authentication Library Client from MU used only for Passwordless Authentication
  setIsAuthenticated: () => {}, // call this function to cause an app rerender. Needed after calling authClient methods that change logged in/out status
  startPasswordlessLogin: () => {}, // client only mutation to start login
  confirmPasswordlessLogin: () => {}, // client only mutation to confirm code
  completePasswordlessLogin: () => {}, // client only mutation to create/complete profile
  cancelPasswordlessLogin: () => {}, // client only mutation to cancel login
  cancelPasswordlessLoginAndKeepLegacy: () => {}, // client only mutation to cancel login
  passwordlessLogin: () => {}, // client only mutation to handle passwordless login
  passwordlessLogout: () => {} // client only mutation to handle passwordless logout
}

const auth$ = new BehaviorSubject<AuthClient>(null)

const dispatchAuthClient = (input?: AuthClient) => {
  auth$.next(input)
}

/**
 * AuthContext used to share AuthContext methods, state from within the corn-guest-auth-spa to
 * consuming microspas
 */
const authContext$ = new BehaviorSubject<AuthContextType>(authContextDefaultValues)

const dispatchAuthContext = (input: AuthContextType) => {
  authContext$.next(input)
}

export { auth$, dispatchAuthClient, authContext$, dispatchAuthContext }
