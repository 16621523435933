import { ReplaySubject, Observable, Subject } from 'rxjs'
import { takeWhile } from 'rxjs/operators'
import { 
  JoinOrCreatePartyInfo,
  JoinOrCreatePartyRequest,
  JoinOrCreatePartyResponse,
  Party,
  PartyMember,
  LookupPartiesByTableResponse,
  LookupPartiesByCheckResponse,
  UpdatePartyMemberResponse,
  ContactInfo,
  UpdatePartyMemberRequest,
 } from './party'
import { ResponseTypes } from '../types.d'

export const party$ = new ReplaySubject<Party>(1)
export const dispatchPartyChange = (party: Party) => party$.next(party)

export const currentUser$ = new ReplaySubject<PartyMember | undefined>(1)
export const dispatchCurrentUser = (currentUser: PartyMember) => currentUser$.next(currentUser)

export const joinOrCreatePartyRequest$ = new Subject<JoinOrCreatePartyRequest>()
export const joinOrCreateParty = (joinOrCreatePartyInfo: JoinOrCreatePartyInfo): Observable<JoinOrCreatePartyResponse> => {
  const response$ = new Subject<JoinOrCreatePartyResponse>()
  const request: JoinOrCreatePartyRequest = {
    header: {
      response$
    },
    joinOrCreatePartyInfo
  }

  const joinOrCreatePartyResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  joinOrCreatePartyRequest$.next(request)

  return joinOrCreatePartyResponse$
}

export const partiesByTable$ = new ReplaySubject<LookupPartiesByTableResponse>()
export const dispatchPartiesByTable = (data: LookupPartiesByTableResponse) => {
  partiesByTable$.next(data)
}

export const partiesByCheck$ = new ReplaySubject<LookupPartiesByCheckResponse>()
export const dispatchPartiesByCheck = (data: LookupPartiesByCheckResponse) => {
  partiesByCheck$.next(data)
}

export const updatePartyMemberRequest$ = new ReplaySubject<UpdatePartyMemberRequest>()
export const updatePartyMember = (partyMember: ContactInfo) => {
  const response$ = new Subject<UpdatePartyMemberResponse>()
  const request: UpdatePartyMemberRequest = {
    header: {
      response$
    },
    partyMember
  }

  const updatePartyMemberResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  updatePartyMemberRequest$.next(request)

  return updatePartyMemberResponse$

}
