import { Subject } from 'rxjs'

export interface Header {
  response$: Subject<PreauthResponse>
}

export interface PreauthRequest {
  header: Header
  checkoutInfo: CheckoutInfo
}

export enum ResponseTypes {
  OK = 'OK',
  ERROR = 'ERROR'
}

export interface Response {
  kind: ResponseTypes.OK
  info: Array<{
    code: any
    message: string
  }>
  warnings: Array<{
    code: any
    message: string
  }>
}


export interface ErrorResponse {
  kind: ResponseTypes.ERROR
  code: any
  message: string
}

export type PreauthResponse = Response | ErrorResponse 

export type SplitResponse = Response | ErrorResponse

export interface CheckoutInfo {
  firstName: string
  lastName: string
  phone: string
  email: string
  encryptedCard: EncryptedCard
}

export interface EncryptedCard {
  encryptionKeyId: string
  encryptedCardDataString: string
  cardFirst6: string
  cardLast4: string
  zipCode: string
  expMonth: string
  expYear: string
}