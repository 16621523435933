import { ReplaySubject } from 'rxjs'

import { PROMO_BANNER_STORAGE } from '../promo_banners_storage'

import {
  PromoBanner,
  PromoBannersSessionData
} from '../types'

const promoBannersSessionData$ = new ReplaySubject<PromoBannersSessionData>()

const dispatchPromoBannerSeen = (
  restaurantGuid: string,
  banner: PromoBanner
) => {
  const BANNERS_SEEN_PATH = [restaurantGuid, 'bannersSeen']
  const PROMO_CODES_SEEN_PATH = [restaurantGuid, 'promoCodesSeen']

  try {
    const bannersSeen = PROMO_BANNER_STORAGE.get(BANNERS_SEEN_PATH) || {}

    PROMO_BANNER_STORAGE.set(BANNERS_SEEN_PATH, {
      ...bannersSeen,
      [banner.bannerGuid]: true
    })

    if (banner.__typename === 'PromoCodeBanner') {
      const promoCodesSeen =
        PROMO_BANNER_STORAGE.get(PROMO_CODES_SEEN_PATH) || {}
      PROMO_BANNER_STORAGE.set(PROMO_CODES_SEEN_PATH, {
        ...promoCodesSeen,
        [banner.promoCode]: true
      })
    }
  } catch {}

  promoBannersSessionData$.next(PROMO_BANNER_STORAGE.get(restaurantGuid))
}

export {
  dispatchPromoBannerSeen,
  promoBannersSessionData$
}
