import { Observable } from 'rxjs'
import { Selection, UpdateCartResponse } from '../types.d'
import { updateCart } from '../cart_api_model/cart_api_model'

type CallMutationFunc = (
  selection: Selection,
  selectionGuid: string
) => Observable<UpdateCartResponse>

export const useUpdateCart = (): CallMutationFunc => {
  return (selection, selectionGuid, isReorder) => updateCart(selection, selectionGuid, isReorder)
}
