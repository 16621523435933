import { Subject, Observable, BehaviorSubject, ReplaySubject } from 'rxjs'
import { takeWhile } from 'rxjs/operators'

import {
  Selection,
  UpdateCartRequest,
  EditItemUserRequest,
  UpdateCartResponse,
  ResponseTypes,
  CartModificationResponse,
  CartError,
  Fulfillment
} from '../types.d'

const updateCartRequest$ = new Subject<UpdateCartRequest>() // goes to takeout-web

function updateCart(
  selection: Selection,
  selectionGuid: string,
  isReorder?: boolean
): Observable<UpdateCartResponse> {
  const response$ = new Subject<UpdateCartResponse>()
  const request: UpdateCartRequest = {
    header: {
      response$
    },
    selection,
    isReorder,
    ...(selectionGuid && { selectionGuid })
  }

  // terminate once an OK or ERROR result is sent
  const addToCartResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  updateCartRequest$.next(request)

  return addToCartResponse$
}

const dispatchCartResponse = (
  request: UpdateCartRequest,
  responseData: CartError | CartModificationResponse
) => {
  // if/else needed for type checking
  if ('message' in responseData) {
    request.header.response$.next({
      kind: ResponseTypes.ERROR,
      ...responseData
    })
  } else {
    request.header.response$.next({
      kind: ResponseTypes.OK,
      ...responseData
    })
  }
}

const dispatchCartDefaultError = (request: UpdateCartRequest) => {
  dispatchCartResponse(request, {
    code: 500,
    message: 'Unable to update cart.'
  })
}

const editItemUserRequest$ = new BehaviorSubject<EditItemUserRequest>(null)

const dispatchEditItemUserRequest = (input: EditItemUserRequest) => {
  editItemUserRequest$.next(input)
}

const fulfillment$ = new ReplaySubject<Fulfillment>()

const dispatchFulfillmentChange = (input: Fulfillment) => {
  fulfillment$.next(input)
}

export {
  updateCartRequest$,
  updateCart,
  dispatchCartResponse,
  dispatchCartDefaultError,
  editItemUserRequest$,
  dispatchEditItemUserRequest,
  fulfillment$,
  dispatchFulfillmentChange
}
