type mapSetter = (map: any) => void
type Path = string | ReadonlyArray<string>

const get = (map: any, path: Path, defValue?: any) => {
  if (!path) {
    return undefined
  }
  const pathArray = typeof path === 'string' ? path.match(/([^[.\]])+/g) : path
  const result = pathArray?.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    map
  )
  return result === undefined ? defValue : result
}

const set = (map: any, path: Path, value: any) => {
  const pathArray = typeof path === 'string' ? path.match(/([^[.\]])+/g) : path

  pathArray?.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {}
    if (i === pathArray.length - 1) acc[key] = value
    return acc[key]
  }, map)
}

const unset = (map: any, path: Path) => {
  const pathArray = typeof path === 'string' ? path.match(/([^[.\]])+/g) : path

  pathArray?.reduce((acc, key, i) => {
    if (i === pathArray.length - 1) delete acc[key]
    return acc[key]
  }, map)
}

export const namespacedStorage = (
  namespace: string,
  storage: Storage = localStorage
) => {
  const getMap = () => {
    const nameSpaceItem = storage.getItem(namespace)

    if (!nameSpaceItem) {
      return {}
    }

    let map = {}

    try {
      map = JSON.parse(nameSpaceItem)
    } catch {}

    return map
  }

  const setMap = (mapSetter: mapSetter) => {
    const map = getMap()
    mapSetter(map)
    try {
      storage.setItem(namespace, JSON.stringify(map))
    } catch {}
  }

  return {
    get: (key: Path) => get(getMap(), key),
    set: (key: Path, value: any) =>
      setMap(map => {
        set(map, key, value)
      }),
    remove: (key: Path) =>
      setMap(map => {
        unset(map, key)
      })
  }
}
