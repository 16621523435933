import { AddShelfInput, Stock, StockFn } from '../types.d'

const stockRoom = new Map<string, Map<string, Stock>>()
const shelves = new Map<string, Set<StockFn>>()

const stockShelf = (shelfName: string) => {
  const shelfFns = shelves.get(shelfName)
  const stock = stockRoom.get(shelfName)

  if (shelfFns && stock) {
    const stockArray = Array.from(stock.values())
    shelfFns.forEach(shelfFn => {
      shelfFn(stockArray)
    })
  }
}

/** Add stock to the shelf */
export const addStock = ({
  shelfName,
  componentId,
  component,
  priority,
  onMount
}: AddShelfInput) => {
  const stock = stockRoom.get(shelfName) || new Map<string, Stock>()
  stock.set(componentId, { priority, component, componentId, onMount })
  // presort
  const sortedStock = new Map(
    [...stock.entries()].sort((a, b) => b[1].priority - a[1].priority)
  )

  stockRoom.set(shelfName, sortedStock)

  // if shelf exists already render the components
  stockShelf(shelfName)
}

export const addShelf = (shelfName: string, stockFn: StockFn) => {
  // can have multiple shelves based off of stockFn
  const shelveFns = shelves.get(shelfName) || new Set<StockFn>()
  shelveFns.add(stockFn)
  shelves.set(shelfName, shelveFns)

  // if shelf has components render them
  stockShelf(shelfName)
}

export const removeShelf = (shelfName: string) => {
  shelves.delete(shelfName)
}

export const clearShelvesAndStock = () => {
  stockRoom.clear()
  shelves.clear()
}
