import { ReplaySubject } from 'rxjs'
import { Upsell } from '../types'


const upsells$ = new ReplaySubject<Upsell[]>(1)

const dispatchUpsells = (input: Upsell[]) => {
  upsells$.next(input)
}

export { upsells$, dispatchUpsells }
