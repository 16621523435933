import { auth$ } from '../auth_api_model'
import { AuthClient } from '../types'
import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import omitBy from 'lodash/omitBy'

let authClientInternal

auth$.subscribe((authClient: AuthClient) => {
  authClientInternal = authClient
})

/**
 * Passthrough link either forwards to the next link or if the authClient is loaded, passes the request off to
 * the authClient link.
 */
export const getCornAuthLink = () =>
  new ApolloLink((operation, forward) =>
    authClientInternal
      ? authClientInternal.authApolloLink.request(operation, forward)
      : forward(operation)
  )

export const getLegacyAuthLink = () =>
  setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =
      JSON.parse(localStorage.getItem('auth') || '{}')['accessToken'] ||
      JSON.parse(localStorage.getItem('accountLinkingStorage') || '{}')[
        'accessToken'
      ] ||
      ''
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'toast-customer-access': token
      }
    }
  })


/**
 *  guest-authentication-js exposes accessing the value “isProfileCreated” on the JWT that we can utilize.
 * This value is populated from guest-profiles, and will either be: `true` - guest has a profile, `false` - guest does not have a profile,
 * or undefined - from before we started setting this value, before “identityProfile” endpoints existed.
 * This means the guest either has a profile, or their profile expired due to not being verified.
 * @param authClient
 * @returns boolean
 */  
export const isProfileCreated = (authClient: AuthClient) => {
  return (
    authClient.userInfo?.isProfileCreated === true ||
    (authClient.userInfo && authClient.userInfo?.isProfileCreated === undefined)
  )
}

/**
 * Middleware that checks whether the guest should have a valid token attached to the header.
 * It is possible for a guest to obtain a valid authorization token
 * without having an account. This is because guests validate their phone number
 * before creating an account in guest-profiles. Unless the guest is going through the
 * complete identity profile mutation or they have a profile, we don't want
 * them sending a "valid token" to our backend. This removes that token.
 */
export const getAuthorizationHeaderLink = () =>
  setContext(({ operationName }, { headers }) => {
    if (!authClientInternal) {
      // guard against race conditions and clients that have not implemented passwordless
      return headers;
    }
    const hasProfile = isProfileCreated(authClientInternal)
    const isAuthenticated = authClientInternal.userInfo
    if (isAuthenticated && !hasProfile && operationName !== 'COMPLETE_IDENTITY_PROFILE') {
      const newHeaders = omitBy(
        headers,
        (_, k) => k.toLowerCase() === 'authorization'
      )
      return { headers: newHeaders }
    }
    return {
      headers
    }
  })
