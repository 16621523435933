import { Subject } from 'rxjs'
import { ContactInfo } from '../party-apis/party_api_model/party'

export interface Header {
  response$: Subject<OrderResponse>
}

export interface OrderRequest {
  header: Header
  contactInfo?: ContactInfo
}

export enum ResponseTypes {
  OK = 'OK',
  ERROR = 'ERROR'
}

export interface Response {
  kind: ResponseTypes.OK
  checkoutInfo: any
  info: Array<{
    code: any
    message: string
  }>
  warnings: Array<{
    code: any
    message: string
  }>
}


export interface ErrorResponse {
  kind: ResponseTypes.ERROR
  code: any
  message: string
}

export type OrderResponse = Response | ErrorResponse
