import { Subject,Observable  } from 'rxjs'
import { takeWhile } from 'rxjs/operators'


import {
  OrderRequest,
  OrderResponse,
  ResponseTypes,
} from '../types.d'


const orderRequest$ = new Subject<OrderRequest>() 

function order(
  contactInfo?: any,
): Observable<OrderResponse> {
  const response$ = new Subject<OrderResponse>()
  const request: OrderRequest = {
    header: {
      response$
    },
    contactInfo
  }

  // terminate once an OK or ERROR result is sent
  const orderResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  orderRequest$.next(request)

  return orderResponse$
}


export {
  orderRequest$,
  order,
}
