import React from 'react'

import { PROMO_BANNER_STORAGE } from '../promo_banners_storage'
import { promoBannersSessionData$ } from '../promo_banners_api_model'

import { PromoBannersSessionData } from '../types'

export const usePromoBannersSessionData = (restaurantGuid?: string) => {
  const [promoBannersSessionData, setPromoBannersSessionData] = React.useState<
    PromoBannersSessionData
  >(() => {
    if (!restaurantGuid) {
      return {}
    }

    try {
      const item = PROMO_BANNER_STORAGE.get(restaurantGuid)
      return item || {}
    } catch {
      return {}
    }
  })

  React.useEffect(() => {
    const promoBannersSessionDataSubscription = promoBannersSessionData$.subscribe(
      (data: PromoBannersSessionData) => {
        setPromoBannersSessionData(data)
      }
    )

    return () => promoBannersSessionDataSubscription.unsubscribe()
  }, [])

  return promoBannersSessionData
}
