import { Subject, Observable } from 'rxjs'
import { takeWhile } from 'rxjs/operators'


import {
  PreauthRequest,
  PreauthResponse,
  ResponseTypes,
  CheckoutInfo,
  SplitResponse
} from '../types.d'

const preauthRequest$ = new Subject<PreauthRequest>() 

function preauth(
  checkoutInfo: CheckoutInfo,
): Observable<PreauthResponse> {
  const response$ = new Subject<PreauthResponse>()
  const request: PreauthRequest = {
    header: {
      response$
    },
    checkoutInfo
  }

  // terminate once an OK or ERROR result is sent
  const preauthResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  preauthRequest$.next(request)

  return preauthResponse$
}

const splitPaymentRequest$ = new Subject() 

function splitPayment(): Observable<SplitResponse> {
  const response$ = new Subject<SplitResponse>()
  const request: any = {
    header: {
      response$
    },
  }

  // terminate once an OK or ERROR result is sent
  const orderResponse$ = response$.pipe(
    takeWhile(
      response =>
        response.kind !== ResponseTypes.OK &&
        response.kind !== ResponseTypes.ERROR,
      true
    )
  )
  splitPaymentRequest$.next(request)

  return orderResponse$
}


export {
  preauthRequest$,
  preauth,
  splitPayment,
  splitPaymentRequest$
}
