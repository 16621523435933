export enum CardType {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Diners = 'DINERS',
  Cit = 'CIT',
  Maestro = 'MAESTRO',
  Laser = 'LASER',
  Solo = 'SOLO',
  Unknown = 'UNKNOWN'
}

export interface CreditCard {
  cardType: CardType
  expirationMonth: number
  expirationYear: number
  expired: boolean
  guid: string
  isPrimary: boolean
  maskedPan: string
}
