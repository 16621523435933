import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { getCornAuthLink } from '@local/auth-apis'
import { getLegacyAuthLink, getAuthorizationHeaderLink  } from '@local/auth-apis/corn-auth-link/corn-auth-link'
import type { ApolloClientConfig } from '@toasttab/do-secundo-cornucopia-types'
import envFromHostname from '@toasttab/env-from-hostname'
import trimEnd from 'lodash/trimEnd'
import { getErrorLink } from './links/error-link'
import { getHttpLink } from './links/http-link'
import { sharedCache } from './shared-cache'

const DEFAULT_CONFIG: ApolloClientConfig = {
  GATEWAY_BASE_URI: undefined,
  BFF_BASE_URI: undefined,
  BFF_PATH: '/consumer-app-bff/v1',
  RESTAURANT_GUID: undefined,
  PKG_VERSION: 'Unknown',
  PKG_NAME: 'Unknown'
}

export const hydrateConfig = (config: ApolloClientConfig): ApolloClientConfig => {
  const env = envFromHostname(window.location.hostname)
  // TODO Need to change this to support sandbox once the BFF can be deployed there.
  const prefix = env === 'prod' ? 'ws-api' : 'ws-preprod-api.eng'

  const GATEWAY_BASE_URI = trimEnd(
    config.GATEWAY_BASE_URI || `https://${prefix}.toasttab.com`,
    '/'
  )
  const BFF_PATH = trimEnd(config.BFF_PATH || DEFAULT_CONFIG.BFF_PATH, '/')
  const BFF_BASE_URI = trimEnd(process.env.BFF_BASE_URI || config.BFF_BASE_URI || `${GATEWAY_BASE_URI}${BFF_PATH}`, '/')

  return {
    GATEWAY_BASE_URI,
    BFF_PATH,
    BFF_BASE_URI,
    RESTAURANT_GUID: config.RESTAURANT_GUID || '',
    PKG_VERSION: config.PKG_VERSION || DEFAULT_CONFIG.PKG_VERSION,
    PKG_NAME: config.PKG_NAME || DEFAULT_CONFIG.PKG_NAME
  }
} 

export const getApolloClient = (config: ApolloClientConfig, withSharedCache: boolean = false) => {
  const hydratedConfig = hydrateConfig(config)

  const linkFactories = [getErrorLink, getCornAuthLink, getLegacyAuthLink, getAuthorizationHeaderLink, getHttpLink]

  // TODO: allow passing in a modified cache
  return new ApolloClient({
    cache: withSharedCache ? sharedCache : new InMemoryCache(),
    link: ApolloLink.from(linkFactories.map(factory => factory(hydratedConfig))),
    name: hydratedConfig.PKG_NAME,
    version: hydratedConfig.PKG_VERSION
  })  
}
