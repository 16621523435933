import { ReplaySubject } from 'rxjs'

import { Restaurant } from '../types'

const restaurant$ = new ReplaySubject<Restaurant>(1)

const dispatchRestaurantConfig = (input: Restaurant) => {
  restaurant$.next(input)
}

export { restaurant$, dispatchRestaurantConfig }
